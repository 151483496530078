var singleBusyFiles = false;
var multipleBusyFiles = false;
export function init_filepond_single(){
    $(".filepond-single").each(function() {
        if ($(this).data("name")) {
            createPoster(
                this,
                $(this).data("name"),
                $(this).data("size"),
                $(this).data("type"),
                $(this).data("url"),
                $(this).data("upload-url")
            );
        } else {
            FilePond.create(this, {
                name: "filepond",
                instantUpload: true,
                server: {
                    timeout: 300000,
                    process: {
                        url: $(this).data("upload-url")
                    }
                }
            });
        }
    });

    $(".filepond-single").on("FilePond:addfilestart", function(e) {
        console.log("addfile");
        singleBusyFiles = true;
        $(".photo-submit").prop("disabled", true);
        $(".photo-submit").html(
            `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...`
        );
    });

    $(".filepond-single").on("FilePond:processfiles", function(e) {
        if(multipleBusyFiles === false){
            $(".photo-submit").prop("disabled", false);
            $(".photo-submit").html("Continue");
        }
        singleBusyFiles = false
    });
}

export function init_filepond_multiple(){
    $(".filepound-multiple").each(function() {
        createMultiplePoster(
            this,
            $(this).data("files"),
            $(this).data("upload-url"),
            $(this).data("remove-url")
        );
    });
}

function createPoster(input, name, size, type, url, upload_url) {
    FilePond.create(input, {
        name: "filepond",
        instantUpload: true,
        files: [{
                options: {
                    file: {
                        name: name,
                        size: size,
                        type: type,
                    },
                    metadata: {
                        poster: url,
                    },
                },
            }],
        server: {
            timeout: 300000,
            process: {
                url: upload_url
            }
        }
    });
}

function createMultiplePoster(input, files, upload_url, delete_url) {
    FilePond.create(input, {
        name: "filepond",
        instantUpload: true,
        files: files,
        imagePreviewHeight: 100,
        filePosterMaxHeight: 100,
        server: {
            timeout: 300000,
            process: {
                url: upload_url
            },
            revert: function (uniqueFileId, load, error) {
                var file_data = JSON.parse(uniqueFileId);
                removeFile(file_data.id, file_data.img_id, delete_url)
                load();
            }
        }
    });

    $(".filepound-multiple").on("FilePond:addfilestart", function(e) {
        console.log("addfile");
        multipleBusyFiles = true;
        $(".photo-submit").prop("disabled", true);
        $(".photo-submit").html(
            `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...`
        );
    });

    $(".filepound-multiple").on("FilePond:processfiles", function(e) {
        if(singleBusyFiles === false){
            $(".photo-submit").prop("disabled", false);
            $(".photo-submit").html("Continue");
        }
        multipleBusyFiles = false
    });

    $(".filepound-multiple").on("FilePond:removefile", function(e) {
        var id = e.detail.file.file["id"];
        var img_id = e.detail.file.file["img_id"];
        removeFile(id, img_id, delete_url)
    });
}

function removeFile(id, img_id, delete_url){
    if(id){
        $.ajax({
            url: delete_url,
            type: "POST",
            datatype: "Script",
            data: { img_id: img_id, id: id },
            success: function(result) {
                // Do something with the result
            },
        });
    }
}
