window.AsLib = {};

$(document).ready(function() {
    var user_location;
    user_location = $("user_location").first();
    AsLib.user_location = [user_location.data('lat'), user_location.data('lng')];
    AsLib.user_location_string = user_location.data('string');
    AsLib.update_location_with_current_loc = function() {
        return navigator.geolocation.getCurrentPosition(function(position) {
            var form;
            form = $("#location_update_form").first();
            form.find("input[name=latitude]").val(position.coords.latitude);
            form.find("input[name=longitude]").val(position.coords.longitude);
            return form.submit();
        });
    };
    return $(".get_location_btn").click(function() {
        return AsLib.update_location_with_current_loc();
    });
});
