let seconds = 30
let timeLeft = seconds
let timerId, txt

$(document).on('click', '.resend-btn', function(e){
    txt = $(this).text()
    $(this).addClass('disabled')
    $(this).removeClass("btn btn-third")
    callAjax($(this).data('url'))

    $(this).text("Resend in ("+ timeLeft +")")

    const countdown = () => {
        if (timeLeft == 0) {
            clearTimeout(timerId);
            $(this).text(txt)
            $(this).addClass("btn btn-third")
            $(this).removeClass("disabled")
            timeLeft = seconds;
        } else {
            timeLeft--;
            $(this).text("Resend in ("+ timeLeft +")")
        }
    }
    timerId = setInterval(countdown, 1000);
});

function callAjax(url){
    $.ajax({
        type: "POST",
        dataType: "script",
        url: url,
        data: {},
        success: function (data) {
        },
    });
}
