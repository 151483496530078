$(document).on("change", "#adv-filter-form select, #adv-filter-form input[type='checkbox']", function () {
    advanceFilterSearch()
});

export function advanceFilterSearch(){
    var filterFormObj = $("#adv-filter-form");
    $(".ad-search-count").html("<i class='fa fa-spinner fa-spin'></i>")
    $.ajax({
        url: "/pages/advance_search",
        type: "POST",
        dataType: "script",
        async: false,
        data: filterFormObj.serialize(),
        success: function(response) {}
    });
}
