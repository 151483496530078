var isSubmit = false;
export function addressLookup() {
    $('.g-location').each(function () {
        var options = {};
        var places = new google.maps.places.Autocomplete(this, options);
        // places.setComponentRestrictions({
        //     country: ["us", "ar", "au", "be", "bz", "br", "ca", "bq", "cl", "cn", "co", "cr", "hr", "cy", "dk", "fr", "pf", "de", "gr", "id", "ie", "it", "my", "mt", "mx", "nl", "nz", "pa", "pt", "es", "th", "tr", "gb"],
        // });
        var countryclass =($(this).data('countryclass'));
        var cityclass =($(this).data('cityclass'));
        var stateclass =($(this).data('stateclass'));
        var zipclass =($(this).data('zipclass'));
        var latclass =($(this).data('latclass'));
        var lngclass =($(this).data('logclass'));
        var formclass =($(this).data('formclass'));

        google.maps.event.addListener(places, 'place_changed', function () {
            var place = places.getPlace();
            if (place.geometry) {
                var lat = place.geometry.location.lat();
                var lng = place.geometry.location.lng();
            }

            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                if (addressType  == "locality") {
                    var city = place.address_components[i]['long_name'];
                }else if (addressType  == "administrative_area_level_1") {
                    var state = place.address_components[i]['long_name'];
                }else if (addressType  == "postal_code") {
                    var postalcode = place.address_components[i]['long_name'];
                }else if (addressType  == "country") {
                    var countryname = place.address_components[i]['long_name'];
                }
            }

            if (cityclass && stateclass){
                $('.'+cityclass).val(city);
                $('.'+stateclass).val(state);
            }
            if (zipclass){
                $('.'+zipclass).val(postalcode);
            }
            if (countryclass){
                var cId = $("."+countryclass + " option:contains('"+ countryname +"')").attr("value")
                $('.'+countryclass).val(cId);
                get_state_select(cId);
            }
            if (latclass && lngclass && lat && lng){
                $('.'+latclass).val(lat);
                $('.'+lngclass).val(lng);
            }
            if(formclass == true){
                search_form()
            }
        });

        google.maps.event.addDomListener(this, 'keydown', function(event) {
            if (event.keyCode === 13) {
                event.preventDefault();
            }
        });
    });
}
$(document).on('change', '.get-state-from-country', function(e){
    get_state_select($(this).val());
});
export function get_state_select(countryname) {
    $.ajax({
        type: "POST",
        dataType: "script",
        url: '/listings/get_state_from_country',
        data: {
            country: countryname
        },
        success: function (data) {
        }
    });
}
export function changeProfile(currObj, is_submit) {
    $('.image').click();
    isSubmit = is_submit;

}
$(document).on('change','.image',function () {
    readURL(this);
    if (isSubmit){
        $(this).closest('form').submit();
    }
});

function readURL(input) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        reader.onload = function (e) {
            if($('.preview').parent().hasClass("avatar-initials")){
                $('.preview').parent().find("span").html("")
            }
            $('.preview').removeClass("d-none").attr('src', e.target.result);
        };
    }
}
