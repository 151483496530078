import HSStepForm from "../custome/step-form";
document.addEventListener("turbolinks:load", function () {
    // INITIALIZATION OF STEP FORM
    // =======================================================
    var listingForm = $("#newListingForm");
    var is_signin = "";
    new HSStepForm($('.listing-step-form'), {
        isValidate: true,
        finish: function() {
            window.onbeforeunload = null;
            if ($("#user-signin-form").length > 0  && !$("#user-signin-form").hasClass('d-none')) {
                is_signin = callSignIn();
                if( is_signin === "true" ){
                    console.log("Called in ")
                    $(".user_forms").html("You are now logged in successfully.");
                    listingForm.submit();
                }
            } else {
                listingForm.submit();
            }
        },
        onStepChanging: function(el, params, currEl){
            if( listingForm.valid() ) {
                if (!currEl.hasClass("step-content-wrapper")){
                    currEl.attr("disabled", true).html("<i class='fa fa-spinner fa-spin'></i>")
                }
                return sendListingForm();
            } else {
                return false;
            }
        },
        onNextStep: function(el, params, nextEl) {
            var nextDataSettings = nextEl.attr('data-hs-step-form-next-options') ? JSON.parse(nextEl.attr('data-hs-step-form-next-options')) : {};
            if (!nextEl.hasClass("step-content-wrapper")){
                nextEl.attr("disabled", false).html("Continue")
            }
            if(nextDataSettings["targetSelector"] == "#postPhotos"){
                if (FilePond.find( $(".filepond-single")[0] ) === null ){
                    init_filepond_single()
                    init_filepond_multiple()
                }
            }
            window.onbeforeunload = function(e) {
                var confirmationMessage = "data you have entered may not be saved.";

                (e || window.event).returnValue = confirmationMessage;     //Gecko + IE
                return confirmationMessage;                                //Webkit, Safari, Chrome etc.
            };
            scrollToTop('.listing-step-form')
        },
        onPrevStep: function() {
            scrollToTop('.listing-step-form')
        }
    }).init();
    function scrollToTop(el) {
        $('html, body').animate({
            scrollTop: $(el).offset().top - 60
        }, 500)
    }
});

$(document).on('click', '.open-signup', function(e){
    $("#user-signin-form").addClass('d-none');
    $("#user-signup-form").removeClass('d-none');
});

$(document).on('click', '.open-signin', function(e){
    $("#user-signup-form").addClass('d-none');
    $("#user-signin-form").removeClass('d-none');
});

$(document).on('focusout', '.listing_email', function(e){
    checkEmailExist($(this).val())
});

// Price Format
$(document).on('keydown', '.price-number-format', function(e){
    enforceFormat(e)
});

$(document).on('keyup', '.price-number-format', function(e){
    formatToPrice(e)
});

$(document).on('keydown', '.foot-to', function(e){
    enforceFormat(e)
});
$(document).on('keyup', '.foot-to', function(e){
    $(".meter-to").val(($(this).val() * $(this).data("con-rate")).toFixed(4))
});

$(document).on('keydown', '.meter-to', function(e){
    enforceFormat(e)
});
$(document).on('keyup', '.meter-to', function(e){
    $(".foot-to").val(($(this).val() / $(this).data("con-rate")).toFixed(4))
});

$(document).on('keydown', '.pound-to', function(e){
    enforceFormat(e)
});
$(document).on('keyup', '.pound-to', function(e){
    $(".kilogram-to").val(($(this).val() * $(this).data("con-rate")).toFixed(4));
});

$(document).on('keydown', '.kilogram-to', function(e){
    enforceFormat(e)
});
$(document).on('keyup', '.kilogram-to', function(e){
    $(".pound-to").val(($(this).val() / $(this).data("con-rate")).toFixed(4));
});

$(document).on('click', '#editEmailBtn', function(e){
    $("#changeEmail").removeClass("d-none");
});
$(document).on('click', '#changeEmailSubmitBtn', function(e){
    changeEmailSubmit($(this))
});

// "user[email]": {required: true, email: true, remote: { url: "/listings/check_user_email", type: "post"}},

function changeEmailSubmit(obj){
    if ($("#changeEmailTxt").val()){
        $.ajax({
            url: obj.data("url"),
            type: "POST",
            dataType: "script",
            async: false,
            data: {email: $("#changeEmailTxt").val()},
            success: function(response) {}
        });
    } else {
        $("#changeEmailError").html("Please enter email address.")
    }
}

function callSignIn(){
    var signinFormObj = $("#listing_sign_in_form");
    var flag = '';
    $.ajax({
        url: signinFormObj.attr('action'),
        type: signinFormObj.attr('method'),
        dataType: "script",
        async: false,
        data: signinFormObj.serialize(),
        success: function(response) {
            flag = eval(response) === true ? "true" : "false"
        }
    });
    return flag;
}

function sendListingForm(){
    var listingFormObj = $("#newListingForm")
    // var data = new FormData(listingFormObj[0]);
    var listingFormObj = $("#newListingForm")
    $("#is_contact_detail_step").val($("#ownersinformation").is(":visible"));
    $("#current_step").val($("#postPhotos").is(":visible") ? "Photo" : "");

    var flag = false;
    $(".error-msg").html('');
    $("#userEmailText").html($("#listing_email").val());
    $("input[name='user[email]'], input[name='email']").val($("#listing_email").val());
    $.ajax({
        url: listingFormObj.attr('action'),
        type: listingFormObj.attr('method'),
        dataType: "script",
        async: false,
        data: listingFormObj.serialize(),
        success: function(response) {
            flag = true;
        },
        error: function(response) {
            $(".error-msg").html(response.responseText);
            $("#ownersinformation button").attr("disabled", false).html("Continue")
            flag = false;
        }
    });
    return flag;
}

function enforceFormat(event){
    // Input must be of a valid number format or a modifier key, and not longer than ten digits
    if(!isNumericInput(event) && !isModifierKey(event)){
        event.preventDefault();
    }
};

function formatToPrice(event){
    if(isModifierKey(event)) {return;}

    // I am lazy and don't like to type things more than once
    const target = event.target;
    const inputs = target.value.split(".")
    const input = inputs[0].replace(/\D/g,'')
    console.log(input)
    if(inputs[0]) {
        target.value = parseFloat(input).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        target.value = inputs[1] ? target.value + "." + inputs[1] : target.value;
    }
};

function isNumericInput(event){
    const key = event.keyCode;
    return ((key >= 48 && key <= 57) || // Allow number line
        (key >= 96 && key <= 105) || // Allow number pad
        (key == 190 || key == 110 )
    );
};

function isModifierKey(event){
    const key = event.keyCode;
    return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
        (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
        (key > 36 && key < 41) || // Allow left, up, right, down
        (
            // Allow Ctrl/Command + A,C,V,X,Z
            (event.ctrlKey === true || event.metaKey === true || key === 190 || key == 110 ) &&
            (key === 65 || key === 67 || key === 86 || key === 88 || key === 90 || key === 190 || key == 110 )
        )
};

function checkEmailExist(email) {
    $.ajax({
        type: "POST",
        dataType: "script",
        url: '/listings/check_user_email',
        data: {
            user: {email: email},
        },
        success: function (data) {
            if(data == "false"){
                $(".user-registered").removeClass('d-none');
                $("#user-signup-form").addClass('d-none');
                $("#user-signin-form").removeClass('d-none');
                $(".listinguserfinishBtn").html('Sign in');
            } else {
                $(".user-registered").addClass('d-none');
                $("#user-signup-form").removeClass('d-none');
                $("#user-signin-form").addClass('d-none');
                $(".listinguserfinishBtn").html('publish');
            }
        }
    });
}

