document.addEventListener("turbolinks:load", function () {
    setNewCardForm();
    $("input[name='pmt_radio']").on("change", function () {
        setNewCardForm();
    });

    $(function () {
        var stripeCardResponseHandler;
        var $cardform = $('#card-form');
        $("input[data-stripe='number']").payment('formatCardNumber');
        $("input[data-stripe='exp-date']").payment('formatCardExpiry');
        $("input[data-stripe='cvc']").payment('formatCardCVC');

        $("#card-submit").on("click", function (event) {
            $cardform.find('.btn-card').prop('disabled', true).html("<i class='fa fa-spinner fa-spin'></i> Loading...");
            if($("input:radio[name='pmt_radio']:checked").val() == "creditCard"){
                Stripe.card.createToken({
                    number: $('#card_number').val(),
                    cvc: $('#card_cvc').val(),
                    exp_month: parseInt($('#exp_date').val().split("/")[0]),
                    exp_year: parseInt($('#exp_date').val().split("/")[1]),
                    name: $('#card_user_name').val(),
                    address_zip: $('#card_zipcode').val()
                }, stripeCardResponseHandler);
                return false;
            }
            else{
                $cardform.get(0).submit();
            }
        });

        stripeCardResponseHandler = function (status, response) {

            if (response.error) {
                $cardform.find('.payment-errors').addClass("alert alert-danger");
                $cardform.find('.payment-errors').text(response.error.message);
                $cardform.find('.btn-card').prop('disabled', false).html("Save");
            } else {
                var token = response.id;
                $cardform.append($('<input type="hidden" name="stripeToken" />').val(token));
                $cardform.get(0).submit();
            }
        };
    });
});

function setNewCardForm() {
    if($("input:radio[name='pmt_radio']:checked").val() == "creditCard"){
        $("#newCardForm").removeClass("d-none")
    }
    else {
        $("#newCardForm").addClass("d-none")
    }
}
