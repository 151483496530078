import consumer from './consumer'

consumer.subscriptions.create({channel: "MessagesChannel"}, {
    connected: function () {
    },
    disconnected: function () {
    },

    received: function (data) {
        set_conversation(data);
        return "";
    },

    notify: function (message) {
        return alert(message);
    },

    speak: function (massage) {
        return this.perform('speak', {
            massage: massage
        });
    }
});

function set_conversation(data) {
    $(".list-discussions-js").html(data.chat_list);
    $("#txt-" + data.uid).val('');
    $("#message-submit").removeAttr('disabled');
    var old = $("#message-"+ data.con_id)[0].scrollHeight
    $("#message-" + data.con_id).append(data.msg_content);
    $(".chat-content").scrollTop($(".chat-content")[0].scrollHeight);
    var current_user_id = $('meta[name=user-id]').attr("content");

    if (current_user_id == data.recpt_id ){
        if ($("#conversation-chat-box").data("conversation-id") == data.con_id ){
            $("#user_conv_"+data.con_id).find('.badge').remove()
            mark_as_read(data.con_id, data.msg_id)
        }
    }
    $("#message-"+ data.con_id).scrollTop(old + $("#message-"+ data.con_id)[0].scrollHeight);
}

function mark_as_read(conversation_id, message_id){
    $.ajax({
        url: "/conversations/" + conversation_id + "/messages/" + message_id + "/mark_as_read",
        type: "GET",
        dataType: "json"
    });
}
