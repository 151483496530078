import HSStepForm from "../custome/step-form";
document.addEventListener("turbolinks:load", function () {
    // INITIALIZATION OF STEP FORM
    // =======================================================
    setOperatorSection("1")
    var insuranceForm = $("#newInsuranceForm");
    if($("#newListingForm").size != 0) {
        initialize_signup_form("newInsuranceForm");
    }
    new HSStepForm($('.insurance-step-form'), {
        isValidate: true,
        finish: function() {
            insuranceForm .submit();
        },
        onStepChanging: function(el, params, currEl){
            return true;
        },
        onNextStep: function(el, params, nextEl) {
            scrollToTop('.insurance-step-form')
        },
        onPrevStep: function() {
            scrollToTop('.insurance-step-form')
        }
    }).init();
    function scrollToTop(el) {
        $('html, body').animate({
            scrollTop: $(el).offset().top - 60
        }, 500)
    }
});

$(document).on("change", "input[name='sailboat_insurance[is_insurance]'], select[name='sailboat_insurance[is_trailer]'], select[name='sailboat_insurance[is_tender]'], select[name='sailboat_insurance[is_survey]'], select[name='sailboat_insurance[is_marina]'], select[name='sailboat_insurance[is_mooring]']", function () {
    if($(this).val() == "true"){
        $("#"+$(this).data('subsection')).removeClass("d-none")
    } else {
        $("#"+$(this).data('subsection')).addClass("d-none").find('input').val("");
    }
});

$(document).on("change", "select[name='sailboat_insurance[operator_count]']", function () {
    setOperatorSection($(this).val())
});

function setOperatorSection(count) {
    if(count === "1") {
        $("#addInsuranceOperatorSection1, #addInsuranceOperatorSection2").addClass("d-none").find('input, select, textarea').prop('disabled', true);
    } else if(count === "2") {
        $("#addInsuranceOperatorSection1").removeClass("d-none").find('input, select, textarea').prop('disabled', false);
        $("#addInsuranceOperatorSection2").addClass("d-none").find('input, select, textarea').prop('disabled', true);
    } else if(count === "3") {
        $("#addInsuranceOperatorSection1, #addInsuranceOperatorSection2").removeClass("d-none").find('input, select, textarea').prop('disabled', false);
    }
}