$(window).scroll(function () {
    sessionStorage.scrollPos = $(window).scrollTop();
});
var init = function () {
    $(window).scrollTop(sessionStorage.scrollPos || 0)
};
window.onload = init;


$(document).on('turbolinks:before-cache', function (e) {
    $("select.select2-hidden-accessible").select2('destroy');
    $('.bootstrap-select').selectpicker('refresh');
    // $('.list-slider').slick('unslick');
    // $('.list-slider').slick('reinit');
    $('.irs').remove();
    // window.onbeforeunload = null;
});

$(document).ready(function() {
    // $(window).on('popstate', function() {
    //     location.reload(true);
    // });
    $('.modal').on("hidden.bs.modal", function (e) { //fire on closing modal box
        if ($('.modal:visible').length) { // check whether parent modal is opend after child modal close
            $('body').addClass('modal-open'); // if open mean length is 1 then add a bootstrap css class to body of the page
        }
    });
});

$(document).on('turbolinks:load', function () {
    $.getScript("https://www.google.com/recaptcha/api.js");
    // set_measurement_unit();
    // if($(".bind-unload").length > 0) {
    //     // window.addEventListener("beforeunload", function (e) {
    //     window.onbeforeunload = function(e) {
    //         var confirmationMessage = "data you have entered may not be saved.";
    //
    //         (e || window.event).returnValue = confirmationMessage;     //Gecko + IE
    //         return confirmationMessage;                                //Webkit, Safari, Chrome etc.
    //     };
    // }
    $("#changeEmailLink").on("click", function(){
        $("#changeEmail").removeClass('d-none');
    });

    $(".megamenu").on("click", function(e) {
        e.stopPropagation();
    });

    $('.remove-listing-img').click(function(event) {
        var modal = $(this).confirmModal();

        if (!modal.is(':visible')) {
            event.preventDefault();
        }
    });

    if($("#listing_description").size != 0) {
        CKEDITOR.on("instanceReady", function (event) {
            $("#listing_description").css({'display': 'inline-block', 'height': '1px'});
        });
    }
    if($("#newListingForm").size != 0) {
        initialize_signup_form("newListingForm");
    }

    if($("#newListingVerifyForm").size != 0 && $("#newListingVerifyForm").data('is-new')) {
        $("#newListingVerifyForm").validate({
            ignore: ""
        })
    }

    $('#TabLength a:first').tab('show');

    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        // $(".list-slider").slick('refresh');
        localStorage.setItem('selectedTab', $(e.target).attr('id'));
    });

    var selectedTab = localStorage.getItem('selectedTab');
    if (selectedTab) {
        $('#' + selectedTab).tab('show');
    }

    $('[data-toggle="tooltip"]').tooltip();
    addressLookup()

    $('.location').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        autoplay:false,
        touchMove: true,
        speed: 500,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false,
                    arrows: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: false,
                    arrows: false,
                    centerMode: true,
                    centerPadding: '80px'
                }
            },
            {
                breakpoint: 560,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: false,
                    dots: false,
                    arrows: false,
                    centerMode: true,
                    centerPadding: '40px'
                }
            },
            {
                breakpoint: 520,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                    arrows: false,
                    centerMode: true,
                    centerPadding: '40px'
                }
            }
        ]
    });

    var sliderPrice, sliderLength;
    if ($(".slider-price-js").length > 0) {
        $(".slider-price-js").each(function(){
            var objFrom = $(this).closest('.form-group').find('.slider-price-input-from')
            var objTo = $(this).closest('.form-group').find('.slider-price-input-to')
            var min_price = parseInt(objFrom.data('min'));
            var max_price = parseInt(objTo.data('max'));
            var allowsearch = $(this).closest('.form-group').find('#slider-price').data('allowsearch');
            var allowadvsearch = $(this).closest('.form-group').find('#slider-price').data('allowadvsearch');

             $(this).ionRangeSlider({
                skin: "round",
                min: min_price,
                max: max_price,
                step: 10000,
                from: objFrom.val()||min_price,
                to: objTo.val()||max_price,
                type: 'double',
                prefix: "$",
                max_postfix: "+",
                prettify_enabled: true,
                prettify_separator: ",",
                // hide_min_max: true,
                 onStart: function (data) {
                     objFrom.val(data.from);
                     objTo.val(data.to);
                 },
                 onChange: function (data) {
                     objFrom.val(data.from);
                     objTo.val(data.to);
                 },
                 onFinish: function (data) {
                     objFrom.val(data.from);
                     objTo.val(data.to);
                     if(allowsearch){
                         search_form();
                     }
                     if(allowadvsearch){
                         advanceFilterSearch();
                     }
                }
            });
            sliderPrice = $(this).data("ionRangeSlider");
        });
    }


    $('input#slider-price-input-from').keypress( _.debounce( function(){
        var val = $(this).val();
         var min = parseInt($("#slider-price-input-from").data('min'))
         var to = parseInt($("#slider-price-input-to").val())
        // validate
        if (val < min) {
            val = min;
        } else if (val > to) {
            val = to;
        }
        sliderPrice.update({
            from: val
        });
        $(this).val(val);
        search_form();
    }, 500 ));
    $('input#slider-price-input-to').keypress( _.debounce( function(){
        var val = $(this).val();
        var from = parseInt($("#slider-price-input-from").val());
        var max = parseInt($("#slider-price-input-to").data('max'))
        // validate
        if (val < from) {
            val = from;
        } else if (val > max) {
            val = max;
        }
        sliderPrice.update({
            to: val
        });

        $(this).val(val);
        search_form();
    }, 500 ));
    if ($(".search-listing-title").length > 0) {
        $(".search-listing-title").keyup(function (){
            // if($(this).val().length > 3){
                search_form()
            // }
            // if($(this).val().length == 0){
            //     search_form()
            // }
        })
    }
    $(".clear-input-search").click(function (){
        $(".search-listing-title").val("")
        search_form()
    })

    if ($(".slider-year-js").length > 0) {
        $(".slider-year-js").each(function(){
            var objFrom = $(this).closest('.form-group').find('#slider-input-from')
            var objTo = $(this).closest('.form-group').find('#slider-input-to')
            var min_year = parseInt(objFrom.data('min'));
            var max_year = parseInt(objTo.data('max'));
            var allowsearch = $(this).closest('.form-group').find('#slider-year').data('allowsearch');
            $(this).ionRangeSlider({
                skin: "round",
                min: min_year,
                max: max_year,
                from: objFrom.val()||min_year,
                to: objTo.val()||max_year,
                type: 'double',
                prettify_enabled: false,
                hide_min_max: true,
                onFinish: function (data) {
                    objFrom.val(data.from);
                    objTo.val(data.to);
                    if(allowsearch){
                        search_form();
                    }
                }
            });
        });
    }

    if ($(".slider-length-js").length > 0) {
        $(".slider-length-js").each(function(){
            var objFrom = $(this).closest('.form-group').find('.slider-snap-input-length-from')
            var objTo = $(this).closest('.form-group').find('.slider-snap-input-length-to')
            var min_length = parseInt(objFrom.data('min'));
            var max_length = parseInt(objTo.data('max'));
            var allowsearch = $(this).closest('.form-group').find('#slider-length').data('allowsearch');
            var allowadvsearch = $(this).closest('.form-group').find('#slider-length').data('allowadvsearch');
            $(this).ionRangeSlider({
                skin: "round",
                step: 5,
                min: min_length,
                max: max_length,
                from: objFrom.val()||min_length,
                to: objTo.val()||max_length,
                type: 'double',
                prefix: "",
                prettify_enabled: true,
                prettify_separator: ",",
                // hide_min_max: true,
                onStart: function (data) {
                    objFrom.val(data.from);
                    objTo.val(data.to);
                },
                onChange: function (data) {
                    objFrom.val(data.from);
                    objTo.val(data.to);
                },
                onFinish: function (data) {
                    objFrom.val(data.from);
                    objTo.val(data.to);
                    if(allowsearch){
                        search_form();
                    }
                    if(allowadvsearch){
                        advanceFilterSearch();
                    }
                }
            });
            sliderLength = $(this).data("ionRangeSlider");
        });
    }

    $('input#slider-snap-input-length-from').keypress( _.debounce( function(){
        var val = $(this).val();
        var min = parseInt($("#slider-snap-input-length-from").data('min'))
        var to = parseInt($("#slider-snap-input-length-to").val())
        // validate
        if (val < min) {
            val = min;
        } else if (val > to) {
            val = to;
        }
        sliderLength.update({
            from: val
        });
        $(this).val(val);
        search_form();
    }, 500 ));

    $('input#slider-snap-input-length-to').keypress( _.debounce( function(){
        var val = $(this).val();
        var from = parseInt($("#slider-snap-input-length-from").val());
        var max = parseInt($("#slider-snap-input-length-to").data('max'))
        // validate
        if (val < from) {
            val = from;
        } else if (val > max) {
            val = max;
        }
        sliderLength.update({
            to: val
        });

        $(this).val(val);
        search_form();
    }, 500 ));

    if($('.list-slider-show').length > 0) {
        $('.list-slider-show').not("slick-initialized").slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            touchMove: true,
            speed: 500,
            autoplaySpeed: 3000,
            arrows: true,
            infinite: false,
            dots: false,
            asNavFor: '.slider-nav'
        });
        $('.slider-nav').slick({
            slidesToShow: 4,
            slidesToScroll: 3,
            // asNavFor: '.list-slider-show',
            arrows: true,
            infinite: false,
            focusOnSelect: false
        });

        $('.slider-nav').on('click', '.slick-slide', function(event) {
            event.preventDefault();
            var goToSingleSlide = $(this).data('slick-index');

            $('.list-slider-show').slick('slickGoTo', goToSingleSlide);
        });
    }

    $('ul[role="tablist"]').hide();
    // $('.see-images').on("click", function () {
    //     $('.gallery').find('a').first().click();
    // });
    $('.gallery').each(function () {
        $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            gallery: {
                enabled: true
            }
        });
    });
    $('.select-multi').select2({});

    new List('state', { valueNames: [ 'name' ] });
    new List('country', { valueNames: [ 'cname' ] });
    new List('manufacture', { valueNames: [ 'mname' ] });

    $(".chk-select-all").on("change", function(){
        if($(this).is(":checked")){
            $(this).closest('.chk-block').find("input[type='checkbox']").prop("checked", true)
        }
        else if($(this).is(":not(:checked)")){
            $(this).closest('.chk-block').find("input[type='checkbox']").prop("checked", false)
        }
    });

    $(".show-filter").click(function(){
        $(".sorting-wrap").removeClass("open");
        $("body").removeClass("sort-open");
        $(".filter").toggleClass("open");
        $("body").toggleClass("filter-open");
    });

    $(".filter-close-btn").click(function(){
        $(".filter").removeClass("open");
        $("body").removeClass("filter-open");
    });

    $(".show-sorting").click(function(){
        $(".filter").removeClass("open");
        $("body").removeClass("filter-open");
        $(".sorting-wrap").toggleClass("open");
        $("body").toggleClass("sort-open");
    });

    $(".sorting-close-btn").click(function(){
        $(".sorting-wrap").removeClass("open");
        $("body").removeClass("sort-open");
    });

    $(".search-top").click(function(){
        $(".search-top-sec").addClass("open");
        $("body").addClass("filter-open");
    });

    $(".close-search").click(function(){
        $(".search-top-sec").removeClass("open");
        $("body").removeClass("filter-open");
    });

    $("#listing_cabins").on( "change", function(){
        if(!parseInt($(this).val()) || parseInt($(this).val()) == 0){
            $(".listing_cabins_layouts").addClass("d-none")
        } else {
            $(".listing_cabins_layouts").removeClass("d-none")
        }
    });

    $("#listing_bathroom_count").on( "change", function(){
        if(!parseInt($(this).val()) || parseInt($(this).val()) == 0){
            $(".listing_bathroom_styles").addClass("d-none")
        } else {
            $(".listing_bathroom_styles").removeClass("d-none")
        }
    });

    $("input[name='listing[interior_cabins][]']").on( "change", function(){
        if ($("input[name='listing[interior_cabins][]']:checked").length > parseInt($("#listing_cabins").val())) {
            $(this).prop('checked', false)
            alert("you have to only select " + parseInt($("#listing_cabins").val()) + " Checkboxes")
        }
    });

    $("input[name='listing[bathroom_styles][]']").on( "change", function(){
        if ($("input[name='listing[bathroom_styles][]']:checked").length > parseInt($("#listing_bathroom_count").val())) {
            $(this).prop('checked', false)
            alert("you have to only select " + parseInt($("#listing_bathroom_count").val()) + " Checkboxes")
        }
    });

    $(window).bind("resize", function () {
        if ($(window).width() <= 992) {
            var height = $(window).height();

            $('.search-top-sec').height(height - 100);
        } else {
            $('.search-top-sec').height("auto");
        }
    }).trigger("resize");


    $(window).bind("resize", function () {
        $(window).bind("scroll", function () {
            if ($(window).width() <= 768) {
                var height = $(document).height() - $('.footer-main').height() - $(window).height();
                scroll = $(document).scrollTop();
                if (scroll < height) {
                    $(".advance-filter-btn").addClass("show");
                } else {
                    $(".advance-filter-btn").removeClass("show");
                }
            }
        })
    }).trigger("resize");

    $('.promo-code-apply').click(function () {
        var code = $('.promo-code-input').val()
        if(code.length > 0){
            $.ajax({
                url: "check_apply_promo_code",
                type: "get",
                dataType: 'script',
                data: {code: code},
                success: function(){

                }
            })
        }
    })


    $(window).bind("resize", function () {
        if ($(window).width() >= 992) {
            var height = $(window).height();

            // $('.advance-filter-main').height(height - 200);
        } else {
            $('.advance-filter-main').height("auto");
        }
    }).trigger("resize");

    var con_url = $("#con_url").val()
    var con_id = $("#conversation_id").val()

    $(".chat-content-scroll").data('ajaxready', true);
    $(".chat-content-scroll").on('scroll', function (e) {
        checkForChatcrollEvent($(this));
    });
    if (typeof con_url !== 'undefined') {
        $.getScript(con_url, function (data, textStatus, jqxhr) {
            $("#message-" + con_id).scrollTop($("#message-" + con_id)[0].scrollHeight);
            listenForChatScrollEvent($(".chat-content-scroll"));
        });
    }
    var listenForChatScrollEvent = function (el) {
        el.on('scroll', function () {
            checkForChatcrollEvent($(this));
        });
    };

    var checkForChatcrollEvent = function (el) {
        var more_users_url;
        more_users_url = void 0;
        if ($(".chat-content-scroll").data('ajaxready') === false) {
            return;
        }
        more_users_url = $('#all-infinite-scrolling-msg .pagination a[rel=next]').attr('href');
        if (more_users_url && el.scrollTop() === 0) {
            $('#loading').removeClass('hidden');
            $(".chat-content-scroll").data('ajaxready', false);
            $.getScript(more_users_url + "&prev_date=" + $("#prev_date").val());
        }
    };

    if($("#map").length){
        initMap($('#map').data("lat"), $('#map').data("lng"))
    }
    if($(window).width() > 992 && $("#sticker").length){
        $("#sticker").sticky({topSpacing: 100, responsiveWidth: true, bottomSpacing: 450});
    }
    $(".custom-file-input").on("change", function() {
        var fileName = $(this).val().split("\\").pop();
        $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
    });

    if($(window).width() <= 768 ){
        $("#adv-filter-sec").remove();
    }
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })

    $("nav .body-fix").on("click", function(){
        $("body").removeClass("body-fixed");
        $("nav").addClass("fixed-top");
        setTimeout(function(){
            if($("#mobNavBarCollapse.show").length){
                $("body").addClass("body-fixed");
                $("nav").removeClass("fixed-top");
            }
        }, 500);
    });

    $(".listing-cookie-store").on("click", function(){
        document.cookie = "listing_e=true"
    });
});

$(".search-ajs").on("change keyup", function () {
    searchComments($(this).val())
});

$(".reset-comment").on("click", function () {
    searchComments("")
});

function searchComments(query){
    $.ajax({
        type: "POST",
        dataType: "script",
        url: '/listing_comments/comment_search',
        data: {query: query, id: $("#listing_id").val()},
        success: function (data) {}
    });
}

$(document).on('change', '.manufacture-item', function () {
    // search[countries][]
    if (!($("input[name='search[countries][]']:checked").val())){
        if ($(this).is(":checked")){
            $("#search-title").html($(this).val() + " sailboats for sale" );
            $("title").html($(this).val() + " sailboats for sale - SailboatsForSale.com" );
            history.pushState(null, '', '/listings/'+$(this).data("slg"));
        } else {
            ($("input[name='search[manufacture][]']:checked").val() != undefined) ? $("#search-title").html($("input[name='search[manufacture][]']:checked").val() + " sailboats for sale" ) : $("#search-title").html("Sailboat Listings" );
        }
    }
    search_form()
});

$(document).on('change', '.currency-item, .status, .year_from, .year_to, .order, .kind-item, .hull-item, .manufacture-item, .material-item, .allow-search .state-item, .country-item, .fuel-item, .kind-of-boat-item, .recently-item, .condition-item, .propulsion-item, .rigging-item, .keel-item, .steering-item', function () {
    search_form()
});

$(document).on('keyup', '.model', function () {
    if ($(this).val().length >= 3 || $(this).val().length == 0) {
        search_form();
    }
});

$(document).on("click", ".see-images", function () {
    // $.magnificPopup.close()
    $('html, body').animate({
        scrollTop: $("#listing-photos").offset().top - 70
    }, 2000);
})

$(document).on("click", ".see-maps", function () {
    $('html, body').animate({
        scrollTop: $("#map-sec").offset().top - 70
    }, 2000);
})

$(document).on("click", ".expand", function () {
    $(this).next().slideToggle(200);
    $(this).toggleClass("open")
});

$(document).on("change", ".country-item", function () {
    if($(this).is(":checked")){
        get_state($(this).data('id'));
        $("#search-title").html("Sailboats for sale in " + $(this).data("txt") );
        $("title").html("Sailboats for sale in " + $(this).data("txt") + " - SailboatsForSale.com" );
        history.pushState(null, '', '/listings/'+$(this).data("slg"));
    }
    else if($(this).is(":not(:checked)")){
        $(".selectedCountry"+$(this).data('id')).remove();
        if ($("input[name='search[countries][]']:checked").val() != undefined) {
            $("#search-title").html("Sailboats for sale in " + $("input[name='search[countries][]']:checked").data("txt"))
            history.pushState(null, '', '/listings/'+$("input[name='search[countries][]']:checked").data("slg"));
        } else {
            $("#search-title").html("Sailboat Listings" )
            history.pushState(null, '', '/listings');
        }
    }
});

$(document).on("change", ".state-item", function () {
    if($(this).is(":checked")){
        $("#search-title").html("Sailboats for sale in " + $(this).data("txt") );
        $("title").html("Sailboats for sale in " + $(this).data("txt") + " - SailboatsForSale.com" );
    }
    else if($(this).is(":not(:checked)")){
        if ($("input[name='search[states][]']:checked").val() != undefined) {
            $("#search-title").html("Sailboats for sale in " + $("input[name='search[states][]']:checked").data("txt"))
        } else {
            if ($("input[name='search[countries][]']:checked").val() != undefined) {
                $("#search-title").html("Sailboats for sale in " + $("input[name='search[countries][]']:checked").data("txt"))
            } else {
                $("#search-title").html("Sailboat Listings" )
            }
        }
    }
});

// $(document).on("change", ".h-country-dropdown", function () {
//     get_home_state($(this).val());
// });

$(document).on("change", ".forum-cat", function () {
    filter_forum_cat($(this).val());
})
$(document).on("change", "#search_sorting", function () {
    search_form();
});
$(document).on("click", "#search_is_verified", function () {
    $("#search_is_verified").val($(this).val());
    search_form();
});
$(document).on("click", "#search_is_featured", function () {
    search_form();
});

$(document).on("click", ".sortbysearch li", function () {
    $(".sortbysearch li").removeClass("selected")
    $(this).addClass("selected")
    $("#search_sorting").val($(this).data('val'));
    $(".sorting-wrap").removeClass("open");
    $("body").removeClass("sort-open");
    search_form();
});

$(document).on("change","input[name='listing[is_phone_public]']", function () {
    if($(this).val() == "true"){
        $(".phonefieldsection").removeClass("d-none")
    } else {
        $(".phonefieldsection").addClass("d-none").find('input').val("");
    }
});
// $(document).on("change","input[name='listing[measurement_unit]']", function () {
//     set_measurement_unit();
// });

// function set_measurement_unit(){
//     if($("input:radio[name='listing[measurement_unit]']:checked").val() == "US Imperial"){
//         $(".ft-m-m").html("ft")
//         $(".lbs-kg-m").html("lbs")
//         $(".ga-kg-m").html("gallons")
//     } else {
//         $(".ft-m-m").html("m")
//         $(".lbs-kg-m").html("kg")
//         $(".ga-kg-m").html("kg")
//     }
// }

function get_state(country_id) {
    $.ajax({
        type: "GET",
        dataType: "script",
        url: '/listings/find_state',
        data: {country_id: country_id},
        success: function (data) { }
    });
}

// function get_home_state(country_id) {
//     $.ajax({
//         type: "GET",
//         dataType: "script",
//         url: '/pages/find_state',
//         data: {country_id: country_id},
//         success: function (data) { }
//     });
// }

export function search_form() {
    $("#page-overlay").fadeIn(300);
    $.ajax({
        url: '/listings',
        type: 'GET',
        dataType: 'script',
        data: $("#filter_form").serializeArray(),
        success: function (result) {}
    });
}

function filter_forum_cat(value) {
    $.ajax({
        url: '/forums',
        type: 'GET',
        dataType: 'script',
        data: {forum_category: value},
        success: function (data) {
        }
    });
}

export function customSharePopup(url) {
    window.open(url, "share_dialog", "width=626,height=450");
}

$(document).on("change","#message_noti, #listing_noti", function () {
    $.ajax({
        type: "POST",
        dataType: "script",
        url: '/notifications',
        data: {
            message_noti: $('#message_noti').is(':checked'),
            listing_noti: $('#listing_noti').is(':checked'),
        },
        success: function (data) {
        }
    });
});
$(document).on("submit", "#new_message", function (){
    $("#message-submit").attr('disabled','disabled')
});

$(document).on("click", ".remove-filter-chk", function (){
    $("input[name='"+$(this).data("name")+"'][value='"+$(this).data("val")+"']").prop("checked", false).change();
    $(this).parent().remove();
    // search_form();
});

$(document).on("change", ".chk-filter", function (){
    if($(this).is(':checked')) {
        $("#filter-option-list").append("<span  class='filter-tag'>" + $(this).data('txt') + "<span class='remove-filter-chk' data-name='"+ $(this).attr("name") +"' data-val='"+ $(this).val() +"'><i class='far fa-times'></i></span></span>")
    } else {
        $("[data-name='"+ $(this).attr("name") +"'][data-val='"+ $(this).val() +"']").parent().remove()
    }
});

$(document).on("change", "#isSocialPostCheckbox", function (){
    listingFeturePrice()
});

// function imageValidation(obj) {
//     obj.find('.imageuploadify-container').remove();
//     obj.find('.img-container').remove();
// }

function initMap(lat, lng) {
    var myCoords = new google.maps.LatLng(lat, lng);
    var mapOptions = {
        center: myCoords,
        zoom: 14
    };
    var map = new google.maps.Map(document.getElementById('map'), mapOptions);
    var marker = new google.maps.Marker({
        position: myCoords,
        map: map
    });
}
export function listingFeturePrice(){
    var totalPrice = parseFloat($("#priceInfo").data("featured-price"))
    if($('#isSocialPostCheckbox').is(':checked')) {
        totalPrice = (totalPrice + parseFloat($('#isSocialPostCheckbox').data("social-price")))
    }
    totalPrice = totalPrice - parseFloat($('#promo_amt').val())
    $("#priceInfo").html("$"+totalPrice.toFixed(2))
}
