// import HSStepForm from "../custome/step-form";

document.addEventListener("turbolinks:load", function () {
    setNotes();
    setIsUsaSelect();
    if($("#newListingVerifyForm").size != 0) {
        initialize_signup_form("newListingVerifyForm");
    }

    $("input[name='listing_verify_request[is_usa]']").on("change", function(){
        setIsUsaSelect()
    });
    $("#listing_verify_request_verification_state_id, #listing_verify_request_verification_country_id").on("change", function(){
        setNotes();
    });

});

function setIsUsaSelect(){
    if($("input[name='listing_verify_request[is_usa]']:checked").val() == "true"){
        $(".select-state").removeClass('d-none');
        $(".select-country").addClass('d-none');
    } else {
        $(".select-state").addClass('d-none');
        $(".select-country").removeClass('d-none');
    }
    setNotes();
}
function setNotes(){
    $('.state-notes').html($("#listing_verify_request_verification_state_id option:selected").data("notes"))
    $('.country-notes').html($("#listing_verify_request_verification_country_id option:selected").data("notes"))
}
