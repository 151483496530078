// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("../theme/as_mapbox.js")
require("../theme/base-custome.js")
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("jquery")
require("bootstrap")
require("popper.js")
require("jquery-validation")
require("bootstrap-slider")
require("wnumb")
require("list.js")
require('data-confirm-modal')
require('underscore')
require('ion-rangeslider')
require("@nathanvda/cocoon");


require("../theme/bootstrap-select.min")
require("../theme/theme")
require("../custome/slick.min")
require("../custome/ajax_loader")
require("../custome/common")
require("../custome/custome")
// require("../custome/imageuploadify.min")
require("../custome/insurance_form")
require("../custome/listing_form")
require("../custome/step-form")
require("../custome/jquery.validate")
require("../custome/jquery.sticky")
require("../custome/jquery.payment")
require("../custome/file_uploader")
require("../custome/payment_handler")
require("../custome/verification_process")
require("../custome/listing_filter")
require("../custome/resend_otp")
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'bootstrap'
import "./stylesheets/application.scss"

global.$ = jQuery;

import _ from 'underscore'
global._ = _;
import * as wNumb from 'wnumb/wNumb.min'
import List from 'list.js'
import initialize_signup_form from '../custome/kassi'
import {addressLookup, changeProfile} from '../custome/common'
import {search_form, customSharePopup, listingFeturePrice} from '../custome/custome'
import {advanceFilterSearch} from '../custome/listing_filter'
import {init_filepond_single, init_filepond_multiple} from '../custome/file_uploader'

import * as FilePond from "filepond";
window.FilePond = FilePond;
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
window.FilePondPluginFileEncode = FilePondPluginFileEncode;
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
window.FilePondPluginFileValidateType = FilePondPluginFileValidateType;
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
window.FilePondPluginImagePreview = FilePondPluginImagePreview;
import FilePondPluginFilePoster from "filepond-plugin-file-poster";
window.FilePondPluginFilePoster = FilePondPluginFilePoster;

FilePond.registerPlugin(
    FilePondPluginFileEncode,
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview,
    FilePondPluginFilePoster
);


window.initialize_signup_form = initialize_signup_form;
window.listingFeturePrice = listingFeturePrice;
window.init_filepond_single = init_filepond_single;
window.init_filepond_multiple = init_filepond_multiple;

window.addressLookup = addressLookup;
window.wNumb = wNumb
window.List = List
window.changeProfile = changeProfile
window.search_form = search_form
window.advanceFilterSearch = advanceFilterSearch
window.customSharePopup = customSharePopup

require("trix")
require("@rails/actiontext")
import 'select2'
import "magnific-popup"

