function add_validator_methods() {

    function check_ck_editor() {
        if (CKEDITOR.instances.listing_description.getData() == '') {
            return false;
        }
        else {
            $("#error_check_editor").empty();
            return true;
        }
    }
    // If some element is required, it should be validated even if it's hidden
    $.validator.setDefaults({ignore: []});

    //$.validator.setDefaults({ ignore: ":hidden:not(.chosen-select)" });

    $.validator.addMethod("accept",
        function (value, element, param) {
            return value.match(new RegExp(/(\.jpe?g|\.gif|\.png|^$)/i));
        }
    );

    $.validator.addMethod("regex",
        function (value, element, regexp) {
            var re = new RegExp(regexp);
            return re.test(value);
        }
    );

    $.validator.addClassRules("required", {
        required: true
    });

    $.validator.addMethod('validUrl', function (value, element) {
        var url = $.validator.methods.url.bind(this);
        return url(value, element) || url('http://' + value, element);
    }, 'Please enter a valid URL');

    $.validator.addClassRules("url", {
        validUrl: true
    });

    $.validator.addMethod("noSpace", function (value, element) {
        return value.indexOf(" ") < 0 && value != "";
    }, "No space please and don't leave it empty");

    $.validator.addMethod("trimSpace", function (value, element) {
        value = value.trim();
        return value != "";
    }, "don't leave it empty");
}

function initialize_defaults() {
    add_validator_methods();
}

function initialize_signup_form(ele_id) {
    add_validator_methods();
    var form_id = "#" + ele_id;
    $(form_id).validate({
        ignore: ":hidden",
        rules: {
            "listing_verify_request[full_name]": {required: true},
            "listing_verify_request[hin]": {required: false, minlength: 12, maxlength: 14},
            "listing_verify_request[is_usa]": {required: true},
            "listing_verify_request[verification_state_id]": {required: true},
            "listing_verify_request[verification_country_id]": {required: true},
            // "listing_verify_request[government_id]": {required: true},
            // "listing_verify_request[boat_certificate]": {required: true},

            "listing[email]": {required: true, email: true},
            "listing[location]": {required: true},
            "listing[manufacturer_ids]": {required: true},
            "listing[year]": {required: true},
            "listing[state]": {required: true},
            "listing[rigging_ids]": {required: true},
            "listing[condition_ids]": {required: true},
            "listing[hull_ids]": {required: true},
            "listing[kind_ids]": {required: true},
            "listing[material_ids]": {required: true},
            "listing[price]": {required: true},
            "listing[length]": {required: true, number: true, min: 1, max: 200
                // max: function(value,element) {
                //     if ($("#listing_length").parent().find(".ft-m-m").text() == 'ft'){
                //         return 200;
                //     }else{
                //         return 61
                //     }
                // }
                },
            "listing[beam]": {required: false, number: true, min: 1, max: 82
                // max: function(value,element) {
                //     if ($("#listing_beam").parent().find(".ft-m-m").text() == 'ft'){
                //         return 82;
                //     }else{
                //         return 25
                //     }
                // }
                },
            "listing[draft]": {required: false, number: true, min: 1,  max: 16
                // max: function(value,element) {
                //     if ($("#listing_draft").parent().find(".ft-m-m").text() == 'ft'){
                //         return 16;
                //     }else{
                //         return 5
                //     }
                // }
                },
            "listing[displacement]": {required: false, number: true, min: 1, max: 100000
                // max: function(value,element) {
                //     if ($("#listing_displacement").parent().find(".lbs-kg-m").text() == 'kg'){
                //         return 45359;
                //     }else{
                //         return 100000
                //     }
                // }
                },
            "listing[cabins]": {required: false, number: true, min: 0},
            "listing[berths]": {required: false, number: true, min: 0},
            "listing[hours]": {required: false, number: true, min: 1},
            "listing[hin]": {required: false, min: 12},
            "listing[bridge_clearance]": {required: false, number: true, min: 1},
            "listing[fuel_capacity]": {required: false, number: true, min: 0},
            "listing[holding_tank]": {required: false, number: true, min: 0},
            "listing[fresh_water]": {required: false, number: true, min: 0},
            "listing[cruising_speed]": {required: false, number: true, min: 1},
            "listing[max_speed]": {required: false, number: true, min: 1},
            "listing[max_capacity]": {required: false, number: true},
            "listing[horsepower]": {required: false, number: true, min: 1},
            "listing[headroom]": {required: false, number: true, min: 1},
            "listing[bathroom_count]": {required: false, number: true, min: 0},
            "listing[zip_code]": {required: false, minlength: 4, maxlength: 10},
            "user[username]": {required: true, minlength: 3, noSpace: true, remote: { url: "/listings/check_user_name", type: "post"}},
            "user[password]": {required: true, minlength: 4, maxlength: 128},
            "user[password_confirmation]": { required: true, equalTo: '#newListingForm [name="user[password]"]'},
            "user[email]": {required: true, email: true, remote: { url: "/listings/check_user_email", type: "post"}},
            "listing[description]":{
                required: function()
                {
                    CKEDITOR.instances.listing_description.updateElement();
                },
                minlength:10
            },
            "hidden-grecaptcha": {
                required: true,
                minlength: "255"
            }

        },
        errorPlacement: function (error, element) {
            if(element.hasClass('image-radio')){
                element.closest(".imageuploadify-images-list").append(error);
            }
            else if(element.hasClass('form-check-input')){
                element.closest('.form-group').append(error);
            }
            else {
                element.closest("div").append(error);
            }
        },
        messages: {
            "user[password]": {
                minlength: "Password must at least be 4 characters"
            },
            "user[username]": {
                required: "Please enter username.",
                minlength: "Username must contain at least 3 characters.",
                noSpace: "Username cannot contain any spaces.",
                remote: "This username is already taken."
            },
            "user[email]": {
                required: "Please enter your email address.",
                email: "Please enter a valid email address.",
                remote: "Email already in use!"
            },
            "listing[description]":{
                required:"This field is required",
                minlength:"Please enter 10 characters"
            }
        },
        onkeyup: false, //Only do validations when form focus changes to avoid exessive ASI calls
        submitHandler: function (form) {
            disable_and_submit(form_id, form, "false");
        }
    });
}

function disable_and_submit(form_id, form, ajax) {
    disable_submit_button(form_id);
    form.submit();
}

function disable_submit_button(form_id) {
    $(form_id).find(".postlistingfinishBtn").prop("disabled", true);
    // add spinner to button
    $(form_id).find(".postlistingfinishBtn").html(
        `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...`
    );
}

export default initialize_signup_form;
initialize_defaults;
